import * as React from "react"

import Post, { IBlogPost } from "./post"

import { graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons"
import Layout from "../layouts/index-layout"

export interface IPaginatedPosts {
  posts: IBlogPost[]
  page: number
  hasNext: boolean
  stem: string
  title: string
}

/**
 * Displays multiple blog posts on a page, with pagination
 * @param props
 */

export default (props) => {
  return (
    <Layout>
      <h1 className="text-2xl">{props.pageContext.title}</h1>
      {props.data.allMdx.edges.map((edge, idx) => (
        <Post key={idx} post={edge.node} />
      ))}
      <div className="mt-6 flex">
        {props.pageContext.prevLink && (
          <Link
            className="flex-none"
            to={props.pageContext.prevLink}
          >
            <FontAwesomeIcon icon={faArrowCircleLeft}/> Newer
          </Link>
        )}
        <span className="flex-auto"/>
        {props.pageContext.nextLink && (
          <Link
            className="flex-none"
            to={props.pageContext.nextLink}
          >
            Older <FontAwesomeIcon icon={faArrowCircleRight}/>
          </Link>
        )}
      </div>
    </Layout>
  )
}

// language=graphql
export const query = graphql`
  query somePosts($limit: Int!, $skip: Int!) {
      allMdx(sort: {fields: frontmatter___date, order: DESC}, limit: $limit, skip: $skip, filter: {frontmatter: {draft: {ne: true}}, fields: {collection: {eq: "posts"}}}) {
          edges {
              node {
                  fields {
                      authorSlug
                      categorySlugs {
                          name
                          slug
                      }
                      slug
                      tagSlugs {
                          name
                          slug
                      }
                  }
                  frontmatter {
                      author
                      date
                      prettyDate: date(formatString: "MMMM Do, YYYY")
                      title
                  }
                  body
              }
          }
      }
  }
`